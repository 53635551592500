// src/components/Navbar.js

import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaBars, FaTimes, FaUserCircle } from "react-icons/fa";
import { auth } from "../firebaseConfig";
import "./Navbar.css";

const Navbar = () => {
  const [menuActive, setMenuActive] = useState(false);
  const [dropdownActive, setDropdownActive] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(setUser);
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setDropdownActive(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  const toggleDropdown = () => {
    setDropdownActive(!dropdownActive);
  };

  const handleLogout = () => {
    auth.signOut().then(() => {
      navigate("/login");
    });
  };

  const logoUrl =
    "https://firebasestorage.googleapis.com/v0/b/event-play-interactive.appspot.com/o/web-images%2Flogo.png?alt=media&token=52870da8-c300-4c71-b253-7ea0e78f1b50";

  return (
    <header className="navbar">
      <div className="navbar-container">
        {/* Logo */}
        <Link to="/" className="logo-link">
          <img src={logoUrl} alt="Logo" className="logo" />
        </Link>

        {/* Menú de Navegación */}
        <nav className={`nav-menu ${menuActive ? "active" : ""}`}>
          {user ? (
            <>
              <Link
                to="/"
                className="nav-link"
                onClick={() => setMenuActive(false)}
              >
                Home
              </Link>
              <Link
                to="/profile"
                className="nav-link"
                onClick={() => setMenuActive(false)}
              >
                Profile
              </Link>
              <button
                onClick={() => {
                  handleLogout();
                  setMenuActive(false);
                }}
                className="nav-link logout-button"
              >
                Logout
              </button>
            </>
          ) : (
            <>
              <Link
                to="/login"
                className="nav-link"
                onClick={() => setMenuActive(false)}
              >
                Login
              </Link>
              <Link
                to="/register"
                className="nav-link"
                onClick={() => setMenuActive(false)}
              >
                Register
              </Link>
            </>
          )}
        </nav>

        {/* Dropdown del Usuario */}
        {user && (
          <div className="user-menu" ref={dropdownRef}>
            <img
              src={user.photoURL || "https://via.placeholder.com/150"}
              alt="Profile"
              className="profile-picture-navbar"
              onClick={toggleDropdown}
            />
            {dropdownActive && (
              <div className="dropdown-menu">
                <Link
                  to="/profile"
                  className="dropdown-item"
                  onClick={() => setDropdownActive(false)}
                >
                  <FaUserCircle /> Profile
                </Link>
                <button
                  onClick={() => {
                    handleLogout();
                    setDropdownActive(false);
                  }}
                  className="dropdown-item"
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        )}

        {/* Icono del Menú - Ahora al Final */}
        <div
          className={`menu-icon ${menuActive ? "active" : ""}`}
          onClick={toggleMenu}
          aria-label="Toggle menu"
          role="button"
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === "Enter") toggleMenu();
          }}
        >
          {menuActive ? (
            <FaTimes size={24} color="#ffffff" />
          ) : (
            <FaBars size={24} color="#ffffff" />
          )}
        </div>
      </div>
    </header>
  );
};

export default Navbar;
