// src/components/GameConfig/ImageUploader.js

import React from 'react';
import { AiOutlineDelete } from 'react-icons/ai';

const ImageUploader = ({
  label,
  images,
  existingImages,
  onAddImage,
  onDeleteImage,
  isLoading,
  multiple = false,
  accept = 'image/*',
}) => {
  return (
    <div className="form-group">
      <label>{label}</label>
      <input
        type="file"
        onChange={onAddImage}
        accept={accept}
        multiple={multiple}
        disabled={isLoading}
      />
      <div className="image-previews">
        {/* New Images */}
        {images.map((image, index) => (
          <div className="image-preview" key={index}>
            <img src={image.url} alt={`New Image ${index + 1}`} />
            <button
              className="btn-delete"
              onClick={() => onDeleteImage(index, 'new')}
              disabled={isLoading}
            >
              <AiOutlineDelete />
            </button>
          </div>
        ))}
        {/* Existing Images */}
        {existingImages.map((url, index) => (
          <div className="image-preview" key={`existing-${index}`}>
            <img src={url} alt={`Existing Image ${index + 1}`} />
            <button
              className="btn-delete"
              onClick={() => onDeleteImage(index, 'existing')}
              disabled={isLoading}
            >
              <AiOutlineDelete />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageUploader;
