// src/components/common/PlayerInfo.js

import React from "react";
import './PlayerInfo.css';

const PlayerInfo = ({ currentPlayer, timer, formatTime }) => {
  return (
    <div className="player-info">
      <h2>Current Player</h2>
      <div className="info-item">Name: {currentPlayer}</div>
      <div className="info-item">Time: {formatTime(timer)}</div>
    </div>
  );
};

export default PlayerInfo;
