import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Home from "./components/Home";
import Register from "./components/Register";
import Login from "./components/Login";
import Profile from "./components/Profile";

import GameConfig from "./components/GameConfig/GameConfig";
import MemoryMatchGame from "./components/games/MemoryMatchGame/MemoryMatchGame";
import JigsawPuzzleGame from "./components/games/JigsawPuzzleGame/JigsawPuzzleGame";
import EventDetail from "./components/EventDetail";
import GameLauncher from "./components/GameLauncher";

const App = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        // Optionally, you can save user data to localStorage
        localStorage.setItem("user", JSON.stringify(user));
      } else {
        setUser(null);
        localStorage.removeItem("user");
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Home user={user} />} />
      <Route
        path="/register"
        element={user ? <Navigate to="/profile" /> : <Register />}
      />
      <Route
        path="/login"
        element={user ? <Navigate to="/profile" /> : <Login />}
      />
      <Route
        path="/profile"
        element={user ? <Profile /> : <Navigate to="/login" />}
      />

      <Route path="/event/:eventId" element={<EventDetail />} />
      <Route path="/game-config/:eventId/:gameId" element={<GameConfig />} />
      <Route path="/launch-game/:eventId/:gameId" element={<GameLauncher />} />
      <Route
        path="/play-memory-match/:eventId/:gameId"
        element={<MemoryMatchGame />}
      />
      <Route
        path="/play-jigsaw-puzzle/:eventId/:gameId"
        element={<JigsawPuzzleGame />}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default App;
