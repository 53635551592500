// src/components/Profile.js

import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { motion } from "framer-motion";
import { FaSignOutAlt, FaHome } from "react-icons/fa";
import LoadingSpinner from "./LoadingSpinner";
import ConfirmModal from "./ConfirmModal";
import "./Profile.css";
import ImageCropper from "./ImageCropper";
import Modal from "react-modal";

import { auth, db, storage } from "../firebaseConfig";
import { updateProfile } from "firebase/auth";
import deleteEvent from "../helpers/deleteEvent";
import CreateEvent from "./CreateEvent";
import EventCard from "./EventCard";

Modal.setAppElement("#root");

const Profile = () => {
  const [user, setUser] = useState(null);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [newProfilePicture, setNewProfilePicture] = useState(null);
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const cropperRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        // Reload the auth user to get the latest data
        await currentUser.reload();

        // Get user data from Firestore
        const userDoc = await getDoc(doc(db, "users", currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUser({
            uid: currentUser.uid,
            email: currentUser.email,
            name: userData.name || currentUser.displayName,
            profilePicture: userData.profilePicture || currentUser.photoURL,
          });
          fetchEvents(currentUser.uid);
        } else {
          navigate("/login");
        }
      } else {
        navigate("/login");
      }
    };
    console.log("USUARIO:", user);
    fetchUser();
  }, [navigate]);

  const fetchEvents = async (userId) => {
    setLoading(true);
    const eventsCollectionRef = collection(db, "users", userId, "events");
    const eventsSnapshot = await getDocs(eventsCollectionRef);
    const eventsList = eventsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setEvents(eventsList);
    setLoading(false);
  };

  const handleEventCreated = () => {
    fetchEvents(user.uid);
  };

  const handleDelete = async () => {
    try {
      await deleteEvent(eventToDelete);
      setEvents(events.filter((event) => event.id !== eventToDelete));
      setEventToDelete(null);
      setModalOpen(false);
    } catch (error) {
      console.error("Error deleting event: ", error);
      alert("There was an error deleting the event. Please try again.");
    }
  };

  const handleProfilePictureChange = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        setNewProfilePicture(reader.result);
        setCropModalOpen(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUploadProfilePicture = async () => {
    setUploading(true);
    try {
      let blobToUpload = null;

      if (cropperRef.current && cropperRef.current.cropper) {
        blobToUpload = await new Promise((resolve) => {
          cropperRef.current.cropper.getCroppedCanvas().toBlob((blob) => {
            resolve(blob);
          });
        });
      }

      if (!blobToUpload) {
        alert("Error cropping the image. Please try again.");
        setUploading(false);
        return;
      }

      const userId = auth.currentUser.uid;
      const storageRef = ref(storage, `users/${userId}/profile-picture`);
      await uploadBytes(storageRef, blobToUpload);

      // Get the download URL
      const profilePictureUrl = await getDownloadURL(storageRef);

      // Update user's profile in Firebase Auth
      await updateProfile(auth.currentUser, {
        photoURL: profilePictureUrl,
      });

      // Update user's document in Firestore
      await updateDoc(doc(db, "users", userId), {
        profilePicture: profilePictureUrl,
      });

      // Reload the user data
      await auth.currentUser.reload();

      // Fetch the updated user data from Firestore
      const userDoc = await getDoc(doc(db, "users", userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setUser({
          uid: auth.currentUser.uid,
          email: auth.currentUser.email,
          name: userData.name || auth.currentUser.displayName,
          profilePicture: userData.profilePicture || auth.currentUser.photoURL,
        });
      }

      // Reset
      setNewProfilePicture(null);
      setCropModalOpen(false);
    } catch (error) {
      console.error("Error updating profile picture: ", error);
      alert(
        `There was an error updating your profile picture: ${error.message}`
      );
    } finally {
      setUploading(false);
    }
  };

  return (
    <motion.div
      className="profile-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="profile-header">
        <h2>Profile</h2>
        <button
          className="logout-button"
          onClick={() => auth.signOut().then(() => navigate("/"))}
        >
          <FaSignOutAlt /> Logout
        </button>
      </div>

      {user && (
        <div className="profile-info">
          <div className="profile-picture-container">
            <img
              src={user.profilePicture || "https://via.placeholder.com/100"}
              alt="Profile"
              className="profile-picture"
            />
            <label htmlFor="profilePictureInput" className="camera-icon">
              <input
                id="profilePictureInput"
                type="file"
                accept="image/*"
                onChange={handleProfilePictureChange}
                style={{ display: "none" }}
              />
              <div className="camera-icon-inner">Change</div>
            </label>
          </div>
          <p className="profile-name">{user.name || "No name provided"}</p>
        </div>
      )}

      {/* Crop Modal */}
      <Modal
        isOpen={cropModalOpen}
        onRequestClose={() => {
          setCropModalOpen(false);
          setNewProfilePicture(null);
        }}
        contentLabel="Crop Image"
        className="crop-modal"
        overlayClassName="crop-modal-overlay"
      >
        <h2 className="modal-title">Crop Your Profile Picture</h2>
        {newProfilePicture && (
          <ImageCropper
            image={newProfilePicture}
            aspectRatio={1}
            cropperRef={cropperRef}
          />
        )}
        <div className="modal-buttons">
          <button
            onClick={handleUploadProfilePicture}
            className="upload-button"
            disabled={uploading}
          >
            {uploading ? "Uploading..." : "Save"}
          </button>
          <button
            onClick={() => {
              setCropModalOpen(false);
              setNewProfilePicture(null);
            }}
            className="cancel-button"
          >
            Cancel
          </button>
        </div>
      </Modal>

      <button className="home-button" onClick={() => navigate("/")}>
        <FaHome /> Home
      </button>

      <CreateEvent onEventCreated={handleEventCreated} />

      {loading ? (
        <LoadingSpinner message={"Loading Events"} />
      ) : (
        <div className="events-section">
          <h3>Events</h3>
          {events.map((event) => (
            <EventCard
              key={event.id}
              event={event}
              onDelete={() => {
                setEventToDelete(event.id);
                setModalOpen(true);
              }}
            />
          ))}
        </div>
      )}

      {modalOpen && (
        <ConfirmModal
          onConfirm={handleDelete}
          onCancel={() => setModalOpen(false)}
          message="Are you sure you want to delete this event?"
        />
      )}
    </motion.div>
  );
};

export default Profile;
