// src/components/GameConfig/CropperModal.js

import React, { useState } from 'react';
import Cropper from 'react-cropper';
import Modal from 'react-modal';
import 'cropperjs/dist/cropper.css';

const CropperModal = ({
  isOpen,
  onRequestClose,
  imageSrc,
  onCrop, // Callback para pasar la imagen recortada y comprimida
  isLoading,
}) => {
  const [cropperInstance, setCropperInstance] = useState(null);

  const handleCrop = () => {
    if (cropperInstance) {
      // Comprimir la imagen utilizando Cropper.js
      cropperInstance.getCroppedCanvas({
        width: 1920, // Ancho máximo
        height: 1920, // Alto máximo
        imageSmoothingQuality: 'high', // Calidad de suavizado
      }).toBlob(
        (blob) => {
          if (!blob) {
            console.error("No se pudo generar el blob de la imagen recortada.");
            return;
          }
          // Opcional: cambiar el tipo de imagen y la calidad aquí
          onCrop(blob);
        },
        'image/webp', // Tipo MIME: JPEG para compresión con pérdida
        0.5 // Calidad (0 a 1)
      );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Recortar Imagen"
      className="Modal"
      overlayClassName="Overlay"
    >
      <h2>Recortar Imagen</h2>
      {imageSrc && (
        <Cropper
          src={imageSrc}
          style={{ height: 400, width: '100%' }}
          initialAspectRatio={1}
          aspectRatio={1}
          guides={false}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false}
          onInitialized={(instance) => setCropperInstance(instance)}
        />
      )}
      <button className="btn-save" onClick={handleCrop} disabled={isLoading}>
        Recortar & Guardar
      </button>
    </Modal>
  );
};

export default CropperModal;
