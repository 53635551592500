// src/components/GameConfig/GameForm.js

import React from 'react';

const GameForm = ({
  gameName,
  setGameName,
  selectedGame,
  setSelectedGame,
  isEditMode,
  isLoading,
}) => {
  return (
    <>
      <div className="form-group">
        <label htmlFor="gameName">Game Name:</label>
        <input
          type="text"
          id="gameName"
          value={gameName}
          onChange={(e) => setGameName(e.target.value)}
          disabled={isLoading}
        />
      </div>
      <div className="form-group">
        <label htmlFor="gameType">Select Game Type:</label>
        {isEditMode ? (
          <input
            type="text"
            id="gameType"
            value={
              selectedGame === 'MemoryMatch'
                ? 'Memory Match'
                : selectedGame === 'JigsawPuzzle'
                ? 'Jigsaw Puzzle'
                : ''
            }
            readOnly
          />
        ) : (
          <select
            id="gameType"
            value={selectedGame}
            onChange={(e) => setSelectedGame(e.target.value)}
            disabled={isLoading}
          >
            <option value="">Select a game</option>
            <option value="MemoryMatch">Memory Match</option>
            <option value="JigsawPuzzle">Jigsaw Puzzle</option>
          </select>
        )}
      </div>
    </>
  );
};

export default GameForm;
