// src/components/Register.js

import React, { useState } from "react";
import { auth, db, storage } from "../firebaseConfig";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import ProfilePictureCropper from "./ProfilePictureCropper";
import "./register.css";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [profilePicture, setProfilePicture] = useState(null);
  const [croppedPicture, setCroppedPicture] = useState(null);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    setError(""); // Clear previous errors

    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      let profilePictureUrl = "";
      if (croppedPicture) {
        // Create a reference to the profile folder in storage
        const storageRef = ref(storage, `users/${user.uid}/profile-picture`);
        await uploadBytes(storageRef, croppedPicture);
        profilePictureUrl = await getDownloadURL(storageRef);
      }

      // Update user's profile
      await updateProfile(user, {
        displayName: name,
        photoURL: profilePictureUrl,
      });

      // Create the user's document in Firestore with the correct structure
      await setDoc(doc(db, "users", user.uid), {
        email: user.email,
        name: name,
        profilePicture: profilePictureUrl,
        subscribed: false,
      });

      // Redirect to user's profile
      navigate("/profile");
    } catch (error) {
      // Handle specific Firebase Authentication errors
      if (error.code === "auth/email-already-in-use") {
        setError("The email address is already in use by another account.");
      } else {
        setError("Registration failed. Please try again.");
      }
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = () => setProfilePicture(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const handleCrop = (blob) => {
    setCroppedPicture(blob);
  };

  return (
    <div className="register-page">
      <div className="register-box">
        <h2>Create an Account</h2>
        <form onSubmit={handleRegister}>
          <div className="form-group">
            <label htmlFor="name">Full Name:</label>
            <input
              id="name"
              type="text"
              placeholder="Enter your full name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="register-input"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="profilePicture">Profile Picture:</label>
            <input
              id="profilePicture"
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className="register-input"
            />
            {profilePicture && (
              <ProfilePictureCropper
                image={profilePicture}
                onCrop={handleCrop}
              />
            )}
            {croppedPicture && (
              <img
                src={URL.createObjectURL(croppedPicture)}
                alt="Cropped Profile Preview"
                className="profile-picture-preview"
              />
            )}
          </div>

          <div className="form-group">
            <label htmlFor="email">Email Address:</label>
            <input
              id="email"
              type="email"
              placeholder="Enter your email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="register-input"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="password">Create Password:</label>
            <input
              id="password"
              type="password"
              placeholder="Create a password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="register-input"
              required
            />
          </div>

          {error && <div className="error-message">{error}</div>}

          <button type="submit" className="register-button">
            Register
          </button>
        </form>
        <p className="register-footer">
          Already have an account? <a href="/login">Login</a>
        </p>
      </div>
    </div>
  );
};

export default Register;
