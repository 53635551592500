import React, { useState } from "react";
import { db, auth } from "../firebaseConfig";
import { collection, addDoc, Timestamp, doc } from "firebase/firestore";
import "./CreateEvent.css";

const CreateEvent = ({ onEventCreated }) => {
  const [eventName, setEventName] = useState("");

  const handleCreateEvent = async () => {
    const user = auth.currentUser;
    if (user) {
      const userDocRef = doc(db, "users", user.uid);
      const eventsCollectionRef = collection(userDocRef, "events");

      try {
        await addDoc(eventsCollectionRef, {
          name: eventName,
          createdAt: Timestamp.now(),
        });
        console.log("Event created successfully");
        setEventName("");
        onEventCreated();
      } catch (error) {
        console.error("Error creating event: ", error);
      }
    } else {
      console.log("User is not authenticated");
    }
  };

  return (
    <div className="create-event-container">
      <h3>Create a New Event</h3>
      <input
        type="text"
        placeholder="Event Name"
        value={eventName}
        onChange={(e) => setEventName(e.target.value)}
      />
      <button onClick={handleCreateEvent}>Create Event</button>
    </div>
  );
};

export default CreateEvent;
