import React, { useEffect } from "react";
import AOS from "aos";
import { FaGamepad, FaCogs, FaUsers } from "react-icons/fa";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Navbar from "./Navbar.js";
import "./Home.css";

const Home = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duración de la animación en milisegundos
    });
  }, []);

  const logoUrl =
    "https://firebasestorage.googleapis.com/v0/b/event-play-interactive.appspot.com/o/web-images%2Flogo.png?alt=media&token=52870da8-c300-4c71-b253-7ea0e78f1b50";

  return (
    <div className="home-container">
      <Navbar />

      <section className="hero-section">
        <motion.div
          className="hero-content"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
        >
          <img src={logoUrl} alt="Logo" className="hero-logo" />
          <div className="hero-text">
            <h1>Welcome to Event Play Interactive</h1>
            <p className="hero-subtitle">
              Transforming your events with immersive games and unforgettable
              fun!
            </p>
            <p>
              Dive into a world of interactive experiences designed to engage
              and delight your guests. From vibrant Memory Match games to
              customizable puzzles, we offer endless possibilities to make your
              event truly special.
            </p>
            <Link to="/register" className="cta-button">
              Get Started
            </Link>
          </div>
        </motion.div>
      </section>

      <section className="features-section">
        <div className="features-content">
          <div className="feature-card" data-aos="fade-up">
            <div className="feature-icon">
              <FaGamepad />
            </div>
            <h3>Interactive Games</h3>
            <p>
              Enjoy a variety of games designed to entertain and engage your
              guests.
            </p>
          </div>
          <div className="feature-card" data-aos="fade-up" data-aos-delay="200">
            <div className="feature-icon">
              <FaCogs />
            </div>
            <h3>Customizable</h3>
            <p>
              Personalize games with your own images and settings to fit your
              event perfectly.
            </p>
          </div>
          <div className="feature-card" data-aos="fade-up" data-aos-delay="400">
            <div className="feature-icon">
              <FaUsers />
            </div>
            <h3>Easy to Use</h3>
            <p>
              Our user-friendly platform makes it easy for anyone to set up and
              play.
            </p>
          </div>
        </div>
      </section>

      <section className="pricing-section">
        <h2>Choose Your Plan</h2>
        <div className="pricing-container">
          <motion.div
            className="pricing-card"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div className="pricing-header">
              <h3>Monthly Subscription</h3>
              <p className="pricing-price">
                $15 <span>/ month</span>
              </p>
            </div>
            <ul className="pricing-features">
              <li>Access to all games</li>
              <li>New games added regularly</li>
              <li>Customizable game settings</li>
              <li>Priority customer support</li>
            </ul>
            <Link to="/pricing" className="cta-button">
              Subscribe Now
            </Link>
          </motion.div>
        </div>
      </section>

      <footer className="footer">
        <div className="footer-content">
          <a href="#" className="footer-logo">
            <img src={logoUrl} alt="Event Play Interactive Logo" />
          </a>
          <nav className="footer-nav">
            <ul>
              <li>
                <a href="#">Privacy Policy</a>
              </li>
              <li>
                <a href="#">Terms of Service</a>
              </li>
              <li>
                <a href="#">Contact Us</a>
              </li>
              <li>
                <a href="#">FAQs</a>
              </li>
            </ul>
          </nav>
          <div className="footer-social">
            <a href="#" className="social-icon">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="#" className="social-icon">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="#" className="social-icon">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="#" className="social-icon">
              <i className="fab fa-linkedin-in"></i>
            </a>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; 2024 Event Play Interactive. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default Home;
