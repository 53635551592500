import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db, auth } from "../firebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import LoadingSpinner from "./LoadingSpinner";

const GameLauncher = () => {
  const { eventId, gameId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [gameData, setGameData] = useState(null);

  useEffect(() => {
    const fetchGameData = async () => {
      const user = auth.currentUser;
      if (!user) {
        console.error("No user is authenticated");
        return;
      }

      const gameDoc = await getDoc(
        doc(db, `users/${user.uid}/events/${eventId}/games/${gameId}`)
      );
      if (gameDoc.exists()) {
        const gameData = gameDoc.data();
        setGameData(gameData);
        setLoading(false);
      } else {
        console.error("No such document!");
        setLoading(false);
      }
    };

    fetchGameData();
  }, [eventId, gameId]);

  useEffect(() => {
    if (!loading && gameData) {
      switch (gameData.gameType) {
        case "MemoryMatch":
          navigate(`/play-memory-match/${eventId}/${gameId}`);
          break;
          case "JigsawPuzzle":
            navigate(`/play-jigsaw-puzzle/${eventId}/${gameId}`);
            break;
        // Add cases for other game types here
        default:
          console.error("Unknown game type");
          break;
      }
    }
  }, [loading, gameData, navigate, eventId, gameId]);

  if (loading) {
    return <LoadingSpinner message={"Loading"} />;
  }

  return <div>Redirecting...</div>;
};

export default GameLauncher;
