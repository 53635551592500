// src/components/GameConfig/MemoryMatchFields.js

import React, { useRef } from "react";
import ImageUploader from "./ImageUploader";
import { AiOutlineDelete } from "react-icons/ai";
import "./MemoryMatchFields.css";

const MemoryMatchFields = ({
  images,
  existingImages,
  onAddImages,
  onDeleteImage,
  backImage,
  existingBackImage,
  onAddBackImage,
  onDeleteBackImage,
  isLoading,
}) => {
  const backImageInputRef = useRef(null); // Creación de la referencia

  const handleAddBackImage = (e) => {
    if (onAddBackImage) {
      onAddBackImage(e);
      // Restablecer el valor del input para permitir la misma imagen nuevamente si es necesario
      if (backImageInputRef.current) {
        backImageInputRef.current.value = null;
      }
    }
  };

  const handleDeleteBackImage = () => {
    if (onDeleteBackImage) {
      onDeleteBackImage();
      // Restablecer el valor del input después de eliminar
      if (backImageInputRef.current) {
        backImageInputRef.current.value = null;
      }
    }
  };

  return (
    <div className="memory-match-fields">
      <ImageUploader
        label="Upload Card Images (exactly 12):"
        images={images}
        existingImages={existingImages}
        onAddImage={onAddImages}
        onDeleteImage={onDeleteImage}
        isLoading={isLoading}
        multiple={true}
        accept="image/*"
      />
      <div className="form-group">
        <label>Upload Card Back Image:</label>
        <input
          type="file"
          onChange={handleAddBackImage}
          accept="image/*"
          disabled={isLoading}
          ref={backImageInputRef} // Asignar la referencia al input
        />
        {/* Priorizar backImage sobre existingBackImage */}
        {(backImage || existingBackImage) && (
          <div className="preview">
            <img
              src={
                backImage ? URL.createObjectURL(backImage) : existingBackImage
              }
              alt="Card Back Preview"
            />
            <button
              className="btn-delete"
              onClick={handleDeleteBackImage}
              disabled={isLoading}
            >
              <AiOutlineDelete />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MemoryMatchFields;
