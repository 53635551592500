// src/components/LoadingSpinner.js

import React from "react";
import "./LoadingSpinner.css";

const LoadingSpinner = ({ message }) => {
  return (
    <div className="loading-overlay">
      <div className="loading-spinner">
        <div className="spinner"></div>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default LoadingSpinner;
