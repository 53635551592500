import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { db, auth, storage } from "../firebaseConfig";
import { collection, doc, getDocs, deleteDoc } from "firebase/firestore";
import { ref, listAll, deleteObject } from "firebase/storage";
import { motion } from "framer-motion";
import { FaCog, FaPlay, FaPlusCircle, FaTrash } from "react-icons/fa";
import Navbar from "./Navbar";
import ConfirmModal from "./ConfirmModal";
import "./EventDetail.css";
import LoadingSpinner from "./LoadingSpinner";

const EventDetail = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [games, setGames] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [gameToDelete, setGameToDelete] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          console.error("No user is authenticated");
          navigate("/login"); // Redirect to login if not authenticated
          return;
        }

        const gamesCollection = collection(
          db,
          `users/${user.uid}/events/${eventId}/games`
        );
        const gamesSnapshot = await getDocs(gamesCollection);
        const gamesList = gamesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setGames(gamesList);
      } catch (error) {
        console.error("Error fetching games: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchGames();
  }, [eventId, navigate]);

  const generateNewGameConfigId = () => {
    const user = auth.currentUser;
    if (!user) return "";

    const userId = user.uid;
    const newGameConfigDocRef = doc(
      collection(db, `users/${userId}/events/${eventId}/games`)
    );
    return newGameConfigDocRef.id;
  };

  const handleDeleteGame = async () => {
    const user = auth.currentUser;
    if (!user) return;

    try {
      const gameDocRef = doc(
        db,
        `users/${user.uid}/events/${eventId}/games/${gameToDelete}`
      );

      // Eliminar todos los archivos del almacenamiento
      const deleteFolder = async (path) => {
        const folderRef = ref(storage, path);
        const folder = await listAll(folderRef);
        const deletePromises = folder.items.map((item) => deleteObject(item));
        await Promise.all(deletePromises);
        const subfolderPromises = folder.prefixes.map((subfolder) =>
          deleteFolder(subfolder.fullPath)
        );
        await Promise.all(subfolderPromises);
      };

      await deleteFolder(
        `users/${user.uid}/events/${eventId}/games/${gameToDelete}`
      );

      // Eliminar el documento del juego en Firestore
      await deleteDoc(gameDocRef);

      // Actualizar el estado de juegos
      setGames(games.filter((game) => game.id !== gameToDelete));
      setShowModal(false);
      setGameToDelete(null);
    } catch (error) {
      console.error("Error deleting game: ", error);
    }
  };

  const confirmDeleteGame = (gameId) => {
    setGameToDelete(gameId);
    setShowModal(true);
  };

  if (loading) {
    return <LoadingSpinner message={"Loading"} />;
  }

  return (
    <div className="event-detail">
      <Navbar />
      <main className="event-detail-main">
        <motion.div
          className="games-container"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {games.length ? (
            games.map((game) => (
              <div key={game.id} className="game-card">
                <h2>{game.gameName || game.gameType}</h2>
                {game.customization?.logoUrl && (
                  <img
                    src={game.customization.logoUrl}
                    alt="Logo"
                    className="game-logo"
                  />
                )}
                <div className="game-actions">
                  <Link
                    to={`/launch-game/${eventId}/${game.id}`}
                    className="btn btn-primary"
                  >
                    <FaPlay /> Play
                  </Link>
                  <Link
                    to={`/game-config/${eventId}/${game.id}`}
                    className="btn btn-secondary"
                  >
                    <FaCog /> Configure
                  </Link>
                  <button
                    className="btn btn-danger"
                    onClick={() => confirmDeleteGame(game.id)}
                  >
                    <FaTrash /> Delete
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p>No games available for this event.</p>
          )}
        </motion.div>
        <Link
          to={`/game-config/${eventId}/${generateNewGameConfigId()}`}
          className="btn btn-add"
        >
          <FaPlusCircle /> Add New Game
        </Link>
      </main>
      {showModal && (
        <ConfirmModal
          message="Are you sure you want to delete this game?"
          onConfirm={handleDeleteGame}
          onCancel={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default EventDetail;
