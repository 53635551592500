// src/components/GameConfig/JigsawPuzzleFields.js

import React from 'react';
import { AiOutlineDelete } from 'react-icons/ai';

const JigsawPuzzleFields = ({
  puzzleImage,
  existingPuzzleImage,
  onAddPuzzleImage,
  onDeletePuzzleImage,
  isLoading,
}) => {
  return (
    <div className="form-group">
      <label>Upload Puzzle Image:</label>
      <input
        type="file"
        onChange={onAddPuzzleImage}
        accept="image/*"
        disabled={isLoading}
      />
      {puzzleImage && (
        <div className="preview">
          <img src={URL.createObjectURL(puzzleImage)} alt="Puzzle Preview" />
        </div>
      )}
      {existingPuzzleImage && (
        <div className="preview">
          <img src={existingPuzzleImage} alt="Existing Puzzle" />
          <button
            className="btn-delete"
            onClick={onDeletePuzzleImage}
            disabled={isLoading}
          >
            <AiOutlineDelete />
          </button>
        </div>
      )}
    </div>
  );
};

export default JigsawPuzzleFields;
