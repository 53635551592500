import React, { useState, useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const ProfilePictureCropper = ({ image, onCrop }) => {
  const [cropper, setCropper] = useState(null);
  const cropperRef = useRef(null);

  const handleCrop = () => {
    if (cropperRef.current && cropperRef.current.cropper) {
      cropperRef.current.cropper.getCroppedCanvas().toBlob((blob) => {
        onCrop(blob);
      });
    }
  };

  return (
    <div>
      <Cropper
        src={image}
        style={{ height: 400, width: "100%" }}
        initialAspectRatio={1}
        aspectRatio={1}
        guides={false}
        ref={cropperRef}
      />
      <button type="button" onClick={handleCrop} className="crop-button">
        Crop
      </button>
    </div>
  );
};

export default ProfilePictureCropper;
