// src/components/common/GameNavbar.js

import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import "./GameNavbar.css";
import { auth, db } from "../../firebaseConfig";
import { getDoc, doc } from "firebase/firestore";

const GameNavbar = ({
  customization,
  onMenuClick,
  navbarLogo,
  onLogoClick,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [profileLogoUrl, setProfileLogoUrl] = useState("");

  useEffect(() => {
    const fetchProfileLogoUrl = async () => {
      if (
        customization.logoOption === "profileLogo" &&
        (!navbarLogo || navbarLogo === "")
      ) {
        const user = auth.currentUser;
        if (user) {
          const photoURL = user.photoURL;
          if (photoURL) {
            setProfileLogoUrl(photoURL);
          } else {
            // Si photoURL no está disponible en Auth, obtenerla de Firestore
            const userDoc = await getDoc(doc(db, "users", user.uid));
            if (userDoc.exists()) {
              const userData = userDoc.data();
              if (userData.profilePicture) {
                setProfileLogoUrl(userData.profilePicture);
              }
            }
          }
        }
      }
    };

    fetchProfileLogoUrl();
  }, [customization.logoOption, navbarLogo]);

  const navbarStyle = {
    backgroundColor: customization.navbarColor || "#5d3dfd",
  };

  const titleStyle = {
    color: customization.navbarTitleTextColor || "#ffffff",
  };

  // Calcular colores de iconos transparentes y opacos
  const iconColorTransparent = hexToRgba("#000000", 0.1);

  const iconColorOpaque = customization.navbarTitleTextColor || "#ffffff";

  const logoSrc =
    navbarLogo ||
    (customization.logoOption === "profileLogo" ? profileLogoUrl : null);

  return (
    <div className="game-navbar" style={navbarStyle}>
      <div className="navbar-logo" onClick={onLogoClick}>
        {logoSrc && <img src={logoSrc} alt="Logo" className="logo" />}
      </div>
      <div className="navbar-title" style={titleStyle}>
        {customization.navbarTitle || "Game Title"}
      </div>
      <div className="navbar-menu">
        <FaBars
          onClick={() => setMenuOpen(!menuOpen)}
          style={{
            fontSize: "1.5em",
            color: iconColorTransparent,
            transition: "color 0.3s",
          }}
        />
        {menuOpen && (
          <div className="dropdown-menu">
            <div
              className="dropdown-item"
              onClick={() => {
                onMenuClick("resetLeaderboard");
                setMenuOpen(false);
              }}
            >
              Reset Leaderboard
            </div>
            <div
              className="dropdown-item"
              onClick={() => {
                onMenuClick("finishGame");
                setMenuOpen(false);
              }}
            >
              Finalize Game
            </div>
            <div
              className="dropdown-item"
              onClick={() => {
                onLogoClick();
                setMenuOpen(false);
              }}
            >
              Return to Event
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// Función auxiliar para convertir color hex a rgba
function hexToRgba(hex, alpha) {
  let r = 255,
    g = 255,
    b = 255;
  if (hex.startsWith("#")) {
    hex = hex.slice(1);
    if (hex.length === 3) {
      r = parseInt(hex[0] + hex[0], 16);
      g = parseInt(hex[1] + hex[1], 16);
      b = parseInt(hex[2] + hex[2], 16);
    } else if (hex.length === 6) {
      r = parseInt(hex.slice(0, 2), 16);
      g = parseInt(hex.slice(2, 4), 16);
      b = parseInt(hex.slice(4, 6), 16);
    }
  }
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export default GameNavbar;
