// src/components/common/Podium/Podium.js

import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";
import { FaTrophy, FaMedal, FaStar } from "react-icons/fa";
import "./Podium.css";

const Podium = ({ topPlayers, onRestart, formatTime }) => {
  const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });

  useEffect(() => {
    // Update confetti dimensions on window resize
    const handleResize = () => {
      setDimensions({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="podium-overlay" role="dialog" aria-modal="true" aria-labelledby="podium-heading">
      <Confetti
        width={dimensions.width}
        height={dimensions.height}
        numberOfPieces={500}
        recycle={false}
        gravity={0.3}
        colors={["#5d3dfd", "#9036d5", "#c42fad", "#f82885"]}
      />
      <div className="podium-container">
        <h2 id="podium-heading">Congratulations to the Winners!</h2>
        <div className="podium">
          {topPlayers.map((player, index) => (
            <div
              key={player.id || index}
              className={`podium-place podium-${index + 1}`}
              aria-label={`Rank ${index + 1}: ${player.name} with time ${formatTime(player.time)}`}
            >
              <div className="podium-rank">
                {index === 0 && <FaTrophy />}
                {index === 1 && <FaMedal />}
                {index === 2 && <FaStar />}
                {index + 1}
              </div>
              <div className="podium-name">{player.name}</div>
              <div className="podium-time">{formatTime(player.time)}</div>
            </div>
          ))}
        </div>
        <button onClick={onRestart} className="restart-button">
          Restart Game
        </button>
      </div>
    </div>
  );
};

export default Podium;
