// src/components/common/Leaderboard.js

import React from 'react';
import './Leaderboard.css';

const Leaderboard = ({ leaderboard, formatTime }) => {
  const topTen = leaderboard.slice(0, 10); // Get only the top 10 entries

  return (
    <div className="leaderboard-container">
      <h2>Leaderboard</h2>
      <div className="leaderboard">
        <ul>
          {topTen.map((entry, index) => (
            <li key={index}>
              {index + 1}. {entry.name}: {formatTime(entry.time || entry.score)}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Leaderboard;
