// src/components/common/Sidebar.js

import React from 'react';
import PlayerInfo from './PlayerInfo';
import Leaderboard from './Leaderboard';
import './Sidebar.css';

const Sidebar = ({
  currentPlayer,
  timer,
  leaderboard,
  formatTime,
  customization,
  isActive, // Added prop to handle active state
}) => {
  const sidebarStyle = {
    backgroundColor: customization.sidebarColor || '#9036d5',
    color: customization.sidebarTextColor || '#ffffff',
  };

  return (
    <div className={`sidebar ${isActive ? 'active' : ''}`} style={sidebarStyle}>
      <PlayerInfo
        currentPlayer={currentPlayer}
        timer={timer}
        formatTime={formatTime}
      />
      <Leaderboard leaderboard={leaderboard} formatTime={formatTime} />
    </div>
  );
};

export default Sidebar;
