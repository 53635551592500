// src/components/GameConfig/GameConfig.js

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { storage, auth, db } from "../../firebaseConfig";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { doc, getDoc, updateDoc, collection, addDoc } from "firebase/firestore";
import { FaSave } from "react-icons/fa";
import "./GameConfig.css";
import LoadingSpinner from "../LoadingSpinner";
import { toast } from "react-toastify";
import GameConfigHeader from "./GameConfigHeader";
import GameForm from "./GameForm";
import GameSpecificFields from "./GameSpecificFields";
import CropperModal from "./CropperModal";
import GameNavbar from "../common/GameNavbar";
import Sidebar from "../common/Sidebar";

const GameConfig = () => {
  const { eventId, gameId } = useParams();
  const navigate = useNavigate();

  // Estados
  const [gameName, setGameName] = useState("");
  const [selectedGame, setSelectedGame] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [customization, setCustomization] = useState({
    navbarColor: "#5d3dfd",
    navbarTitle: "",
    navbarTitleTextColor: "#ffffff",
    logoOption: "noLogo",
    sidebarColor: "#9036d5",
    sidebarTextColor: "#ffffff",
    cardBorderColor: "#000000",
    gameboardBackgroundColor: "#ffffff",
  });

  const [images, setImages] = useState([]);
  const [existingImages, setExistingImages] = useState([]);
  const [backImage, setBackImage] = useState(null);
  const [existingBackImage, setExistingBackImage] = useState(null);
  const [puzzleImage, setPuzzleImage] = useState(null);
  const [existingPuzzleImage, setExistingPuzzleImage] = useState(null);

  const [navbarLogo, setNavbarLogo] = useState(null);
  const [existingNavbarLogo, setExistingNavbarLogo] = useState(null);

  const [profileLogoUrl, setProfileLogoUrl] = useState("");

  const [cropperModalOpen, setCropperModalOpen] = useState(false);
  const [currentImageFile, setCurrentImageFile] = useState(null);
  const [currentImageType, setCurrentImageType] = useState("");

  const [imagesToDelete, setImagesToDelete] = useState([]);

  const [pendingFiles, setPendingFiles] = useState([]);

  useEffect(() => {
    const fetchGameData = async () => {
      setIsLoading(true);
      const user = auth.currentUser;
      if (!user) {
        setIsLoading(false);
        return;
      }

      if (gameId) {
        try {
          const gameDoc = await getDoc(
            doc(db, `users/${user.uid}/events/${eventId}/games/${gameId}`)
          );
          if (gameDoc.exists()) {
            const gameData = gameDoc.data();
            setGameName(gameData.gameName || "");
            setSelectedGame(gameData.gameType || "");
            setCustomization({
              navbarColor: gameData.customization?.navbarColor || "#5d3dfd",
              navbarTitle: gameData.customization?.navbarTitle || "",
              navbarTitleTextColor:
                gameData.customization?.navbarTitleTextColor || "#ffffff",
              logoOption: gameData.customization?.logoOption || "noLogo",
              sidebarColor: gameData.customization?.sidebarColor || "#9036d5",
              sidebarTextColor:
                gameData.customization?.sidebarTextColor || "#ffffff",
              cardBorderColor:
                gameData.customization?.cardBorderColor || "#000000",
              gameboardBackgroundColor:
                gameData.customization?.gameboardBackgroundColor || "#ffffff",
            });
            if (gameData.gameType === "MemoryMatch") {
              setExistingImages(gameData.customization.images || []);
              setExistingBackImage(gameData.customization.backImageUrl || null);
            } else if (gameData.gameType === "JigsawPuzzle") {
              setExistingPuzzleImage(
                gameData.customization.puzzleImageUrl || null
              );
            }
            if (gameData.customization.navbarLogoUrl) {
              setExistingNavbarLogo(gameData.customization.navbarLogoUrl);
            } else {
              setExistingNavbarLogo(null);
            }
            setIsEditMode(true);
            const userProfileLogoUrl = await getUserProfileLogoUrl(user.uid);
            setProfileLogoUrl(userProfileLogoUrl);
          }
        } catch (error) {
          console.error("Error fetching game data:", error);
          setIsLoading(false);
          return;
        }
      } else {
        setIsEditMode(false);
        const userProfileLogoUrl = await getUserProfileLogoUrl(user.uid);
        setProfileLogoUrl(userProfileLogoUrl);
      }
      setIsLoading(false);
    };

    fetchGameData();
  }, [eventId, gameId]);

  const handleCustomizationChange = (e) => {
    const { name, value } = e.target;
    setCustomization((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddImages = (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 0) {
      setPendingFiles(files);
      processNextImage([...files]); // Copiar el array para evitar mutaciones
    }
  };

  const processNextImage = (files) => {
    const file = files.shift();
    if (file) {
      setCurrentImageFile(file);
      setCurrentImageType("memoryImage");
      setCropperModalOpen(true);
      setPendingFiles(files);
    } else {
      setCropperModalOpen(false);
      setCurrentImageFile(null);
      setCurrentImageType("");
    }
  };

  const handleAddBackImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCurrentImageFile(file);
      setCurrentImageType("backImage");
      setCropperModalOpen(true);
    }
  };

  const handleAddPuzzleImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCurrentImageFile(file);
      setCurrentImageType("puzzleImage");
      setCropperModalOpen(true);
    }
  };

  const handleAddNavbarLogo = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCurrentImageFile(file);
      setCurrentImageType("navbarLogo");
      setCropperModalOpen(true);
    }
  };

  const handleDeleteImage = (index, type) => {
    if (type === "new") {
      const updatedImages = [...images];
      updatedImages.splice(index, 1);
      setImages(updatedImages);
    } else if (type === "existing") {
      const url = existingImages[index];
      setImagesToDelete((prev) => [...prev, url]);
      const updatedExistingImages = [...existingImages];
      updatedExistingImages.splice(index, 1);
      setExistingImages(updatedExistingImages);
    }
  };

  const handleDeleteBackImage = () => {
    if (existingBackImage) {
      setImagesToDelete((prev) => [...prev, existingBackImage]);
      setExistingBackImage(null);
    }
    setBackImage(null);
  };

  const handleDeletePuzzleImage = () => {
    if (existingPuzzleImage) {
      setImagesToDelete((prev) => [...prev, existingPuzzleImage]);
      setExistingPuzzleImage(null);
    }
    setPuzzleImage(null);
  };

  const handleDeleteNavbarLogo = () => {
    if (existingNavbarLogo) {
      setImagesToDelete((prev) => [...prev, existingNavbarLogo]);
      setExistingNavbarLogo(null);
    }
    setNavbarLogo(null);
  };

  // Función handleCrop actualizada para comprimir la imagen
  const handleCrop = async (croppedBlob) => {
    if (!croppedBlob) {
      toast.error("No se pudo obtener la imagen recortada.");
      setCropperModalOpen(false);
      setCurrentImageFile(null);
      setCurrentImageType("");
      return;
    }

    try {
      // Crear un nuevo archivo con el Blob comprimido
      const compressedFile = new File([croppedBlob], currentImageFile.name, {
        type: croppedBlob.type, // 'image/jpeg'
        lastModified: Date.now(),
      });

      // Crear una URL para previsualización
      const compressedUrl = URL.createObjectURL(croppedBlob);

      switch (currentImageType) {
        case "memoryImage":
          setImages((prev) => [
            ...prev,
            { file: compressedFile, url: compressedUrl },
          ]);
          if (pendingFiles.length > 0) {
            processNextImage([...pendingFiles]); // Pasar una copia
          } else {
            setCropperModalOpen(false);
            setCurrentImageFile(null);
            setCurrentImageType("");
          }
          break;
        case "backImage":
          setBackImage(compressedFile);
          setCropperModalOpen(false);
          setCurrentImageFile(null);
          setCurrentImageType("");
          break;
        case "puzzleImage":
          setPuzzleImage(compressedFile);
          setCropperModalOpen(false);
          setCurrentImageFile(null);
          setCurrentImageType("");
          break;
        case "navbarLogo":
          setNavbarLogo(compressedFile);
          setCropperModalOpen(false);
          setCurrentImageFile(null);
          setCurrentImageType("");
          break;
        default:
          break;
      }
    } catch (error) {
      console.error("Error al procesar la imagen:", error);
      toast.error("Ocurrió un error al procesar la imagen.");
      setCropperModalOpen(false);
      setCurrentImageFile(null);
      setCurrentImageType("");
    }
  };

  const handleSaveConfig = async () => {
    setIsLoading(true);
    const user = auth.currentUser;
    if (!user) {
      setIsLoading(false);
      return;
    }

    if (!gameName.trim()) {
      toast.error("Game name cannot be empty.");
      setIsLoading(false);
      return;
    }

    if (!selectedGame) {
      toast.error("Please select a game type.");
      setIsLoading(false);
      return;
    }

    let finalGameId = gameId;

    try {
      let gameDocRef;
      if (isEditMode) {
        gameDocRef = doc(
          db,
          `users/${user.uid}/events/${eventId}/games/${gameId}`
        );
      } else {
        const docRef = await addDoc(
          collection(db, `users/${user.uid}/events/${eventId}/games`),
          {
            gameName,
            gameType: selectedGame,
            customization: {},
          }
        );
        finalGameId = docRef.id;
        gameDocRef = doc(
          db,
          `users/${user.uid}/events/${eventId}/games/${finalGameId}`
        );
      }

      const storageRefPath = `users/${user.uid}/events/${eventId}/games/${finalGameId}`;
      const storageRefFull = ref(storage, storageRefPath);

      let customizationData = { ...customization };

      if (selectedGame === "MemoryMatch") {
        // Subir nuevas imágenes y obtener sus URLs
        const newImageUrls = await Promise.all(
          images.map(async (image) => {
            const imageRef = ref(
              storageRefFull,
              `images/${Date.now()}_${image.file.name}`
            );
            await uploadBytes(imageRef, image.file);
            const downloadUrl = await getDownloadURL(imageRef);
            return downloadUrl;
          })
        );

        // Preparar todas las imágenes (las existentes menos las eliminadas, más las nuevas)
        const allImages = [...existingImages, ...newImageUrls];

        customizationData.images = allImages;

        // Manejar la imagen de fondo
        if (backImage) {
          const backImageRef = ref(
            storageRefFull,
            `backImage_${Date.now()}_${backImage.name}`
          );
          await uploadBytes(backImageRef, backImage);
          const backImageUrl = await getDownloadURL(backImageRef);
          customizationData.backImageUrl = backImageUrl;
        } else if (existingBackImage) {
          customizationData.backImageUrl = existingBackImage;
        } else {
          customizationData.backImageUrl = null;
        }
      } else if (selectedGame === "JigsawPuzzle") {
        if (puzzleImage) {
          const puzzleImageRef = ref(
            storageRefFull,
            `puzzleImage_${Date.now()}_${puzzleImage.name}`
          );
          await uploadBytes(puzzleImageRef, puzzleImage);
          const puzzleImageUrl = await getDownloadURL(puzzleImageRef);
          customizationData.puzzleImageUrl = puzzleImageUrl;
        } else if (existingPuzzleImage) {
          customizationData.puzzleImageUrl = existingPuzzleImage;
        } else {
          customizationData.puzzleImageUrl = null;
        }
      }

      if (customization.logoOption === "uploadLogo") {
        if (navbarLogo) {
          const navbarLogoRef = ref(
            storageRefFull,
            `navbarLogo_${Date.now()}_${navbarLogo.name}`
          );
          await uploadBytes(navbarLogoRef, navbarLogo);
          const navbarLogoUrl = await getDownloadURL(navbarLogoRef);
          customizationData.navbarLogoUrl = navbarLogoUrl;
        } else if (existingNavbarLogo) {
          customizationData.navbarLogoUrl = existingNavbarLogo;
        } else {
          customizationData.navbarLogoUrl = "";
        }
      } else if (customization.logoOption === "profileLogo") {
        customizationData.navbarLogoUrl = profileLogoUrl;
      } else {
        customizationData.navbarLogoUrl = "";
      }

      // Eliminar imágenes marcadas para eliminación
      try {
        await Promise.all(
          imagesToDelete.map(async (url) => {
            const storagePath = getStoragePathFromUrl(url);
            console.log(
              "Attempting to delete image at storage path:",
              storagePath
            );
            if (storagePath) {
              const imageRef = ref(storage, storagePath);
              await deleteObject(imageRef);
              console.log(
                "Successfully deleted image at storage path:",
                storagePath
              );
            } else {
              console.error("Invalid storage path extracted from URL:", url);
              throw new Error(`Invalid storage path for URL: ${url}`);
            }
          })
        );
        setImagesToDelete([]);
      } catch (error) {
        console.error("Error deleting images:", error);
        toast.error(`Failed to delete some images: ${error.message}`);
        // Continuar con el resto de la operación de guardado
      }

      // Actualizar documento en Firestore
      await updateDoc(gameDocRef, {
        gameName,
        gameType: selectedGame,
        customization: customizationData,
      });
      navigate(`/event/${eventId}`);
    } catch (error) {
      console.error("Error saving configuration:", error);
      toast.error("Failed to save the game configuration.");
      setIsLoading(false);
      return;
    } finally {
      setIsLoading(false);
    }
  };

  // Función para obtener la ruta de almacenamiento desde la URL de descarga
  const getStoragePathFromUrl = (url) => {
    try {
      const decodedUrl = decodeURIComponent(url);
      const regex = /\/o\/(.*?)\?alt=media/;
      const matches = decodedUrl.match(regex);
      if (matches && matches[1]) {
        return matches[1];
      } else {
        console.error("Could not extract storage path from URL:", url);
        return null;
      }
    } catch (error) {
      console.error("Error in getStoragePathFromUrl:", error);
      return null;
    }
  };

  const gameStyles = {
    "--sidebar-bg": customization.sidebarColor || "#9036d5",
    "--sidebar-text-color": customization.sidebarTextColor || "#ffffff",
    "--navbar-bg": customization.navbarColor || "#5d3dfd",
    "--navbar-title-text-color":
      customization.navbarTitleTextColor || "#ffffff",
    "--gameboard-background-color":
      customization.gameboardBackgroundColor || "#ffffff",
    "--card-border-color": customization.cardBorderColor || "#000000",
  };

  return (
    <div className="game-config">
      {isLoading && <LoadingSpinner message="Processing..." />}
      <GameConfigHeader isEditMode={isEditMode} isLoading={isLoading} />
      <div className={`form-section ${isLoading ? "disabled" : ""}`}>
        <GameForm
          gameName={gameName}
          setGameName={setGameName}
          selectedGame={selectedGame}
          setSelectedGame={setSelectedGame}
          isEditMode={isEditMode}
          isLoading={isLoading}
        />
        {/* Campos de Personalización */}
        <div className="customization-fields">
          <h3>Customize Your Game</h3>

          {/* Inputs de Color Agrupados */}
          <div className="color-inputs">
            <div className="form-group">
              <label>Navbar Color:</label>
              <input
                type="color"
                name="navbarColor"
                value={customization.navbarColor || "#5d3dfd"}
                onChange={handleCustomizationChange}
                disabled={isLoading}
              />
            </div>

            <div className="form-group">
              <label>Navbar Title Text Color:</label>
              <input
                type="color"
                name="navbarTitleTextColor"
                value={customization.navbarTitleTextColor || "#ffffff"}
                onChange={handleCustomizationChange}
                disabled={isLoading}
              />
            </div>

            <div className="form-group">
              <label>Sidebar Color:</label>
              <input
                type="color"
                name="sidebarColor"
                value={customization.sidebarColor || "#9036d5"}
                onChange={handleCustomizationChange}
                disabled={isLoading}
              />
            </div>

            <div className="form-group">
              <label>Sidebar Text Color:</label>
              <input
                type="color"
                name="sidebarTextColor"
                value={customization.sidebarTextColor || "#ffffff"}
                onChange={handleCustomizationChange}
                disabled={isLoading}
              />
            </div>

            {/* Personalizaciones Específicas para Memory Match */}
            {selectedGame === "MemoryMatch" && (
              <>
                <div className="form-group">
                  <label>Gameboard Background Color:</label>
                  <input
                    type="color"
                    name="gameboardBackgroundColor"
                    value={customization.gameboardBackgroundColor || "#ffffff"}
                    onChange={handleCustomizationChange}
                    disabled={isLoading}
                  />
                </div>

                <div className="form-group">
                  <label>Card Border Color:</label>
                  <input
                    type="color"
                    name="cardBorderColor"
                    value={customization.cardBorderColor || "#000000"}
                    onChange={handleCustomizationChange}
                    disabled={isLoading}
                  />
                </div>
              </>
            )}
          </div>

          {/* Otros Campos de Personalización */}
          <div className="other-customizations">
            <div className="form-group">
              <label>Navbar Title:</label>
              <input
                type="text"
                name="navbarTitle"
                value={customization.navbarTitle || ""}
                onChange={handleCustomizationChange}
                disabled={isLoading}
                placeholder="Enter Game Title"
              />
            </div>

            {/* Opciones de Logo de Navbar */}
            <div className="form-group">
              <label>Navbar Logo:</label>
              <div className="logo-options">
                <label>
                  <input
                    type="radio"
                    name="logoOption"
                    value="profileLogo"
                    checked={customization.logoOption === "profileLogo"}
                    onChange={handleCustomizationChange}
                    disabled={isLoading}
                  />
                  Use Profile Logo
                </label>
                <label>
                  <input
                    type="radio"
                    name="logoOption"
                    value="uploadLogo"
                    checked={customization.logoOption === "uploadLogo"}
                    onChange={handleCustomizationChange}
                    disabled={isLoading}
                  />
                  Upload New Logo
                </label>
                <label>
                  <input
                    type="radio"
                    name="logoOption"
                    value="noLogo"
                    checked={customization.logoOption === "noLogo"}
                    onChange={handleCustomizationChange}
                    disabled={isLoading}
                  />
                  No Logo
                </label>
              </div>
              {customization.logoOption === "uploadLogo" && (
                <div className="form-group">
                  <input
                    type="file"
                    name="navbarLogo"
                    accept="image/*"
                    onChange={handleAddNavbarLogo}
                    disabled={isLoading}
                  />
                  {navbarLogo && (
                    <div className="preview">
                      <img
                        src={URL.createObjectURL(navbarLogo)}
                        alt="Navbar Logo Preview"
                      />
                      <button
                        className="btn-delete"
                        onClick={handleDeleteNavbarLogo}
                        disabled={isLoading}
                      >
                        Delete
                      </button>
                    </div>
                  )}
                  {existingNavbarLogo && (
                    <div className="preview">
                      <img
                        src={existingNavbarLogo}
                        alt="Existing Navbar Logo"
                      />
                      <button
                        className="btn-delete"
                        onClick={handleDeleteNavbarLogo}
                        disabled={isLoading}
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Vista Previa en Vivo */}
        <div className="live-preview">
          <h3>Live Preview</h3>
          <div className="preview-container" style={gameStyles}>
            <GameNavbar
              customization={customization}
              onMenuClick={() => {}}
              navbarLogo={
                customization.logoOption === "uploadLogo" && navbarLogo
                  ? URL.createObjectURL(navbarLogo)
                  : customization.logoOption === "profileLogo"
                  ? profileLogoUrl
                  : null
              }
            />
            <div className="game-layout">
              <Sidebar
                customization={customization}
                currentPlayer="Player Name"
                timer={0}
                leaderboard={[]}
                formatTime={(time) => "00:00"}
              />
              <div
                className="gameboard-preview"
                style={{
                  backgroundColor:
                    customization.gameboardBackgroundColor || "#ffffff",
                }}
              >
                {/* Simulated Game Preview */}
                {selectedGame === "MemoryMatch" && (
                  <div className="cards-preview">
                    {[...Array(6)].map((_, index) => (
                      <div
                        key={index}
                        className="card-preview"
                        style={{
                          borderColor:
                            customization.cardBorderColor || "#000000",
                        }}
                      ></div>
                    ))}
                  </div>
                )}
                {selectedGame === "JigsawPuzzle" && (
                  <div className="puzzle-preview">
                    {/* Placeholder for Jigsaw Puzzle Preview */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <GameSpecificFields
          selectedGame={selectedGame}
          images={images}
          existingImages={existingImages}
          onAddImages={handleAddImages}
          onDeleteImage={handleDeleteImage}
          backImage={backImage}
          existingBackImage={existingBackImage}
          onAddBackImage={handleAddBackImage}
          onDeleteBackImage={handleDeleteBackImage}
          puzzleImage={puzzleImage}
          existingPuzzleImage={existingPuzzleImage}
          onAddPuzzleImage={handleAddPuzzleImage}
          onDeletePuzzleImage={handleDeletePuzzleImage}
          isLoading={isLoading}
          customization={customization}
          handleCustomizationChange={handleCustomizationChange}
          navbarLogo={navbarLogo}
          existingNavbarLogo={existingNavbarLogo}
          handleAddNavbarLogo={handleAddNavbarLogo}
          handleDeleteNavbarLogo={handleDeleteNavbarLogo}
          profileLogoUrl={profileLogoUrl}
        />
        <button
          className="btn-save"
          onClick={handleSaveConfig}
          disabled={isLoading}
        >
          <FaSave /> {isEditMode ? "Update Game" : "Create Game"}
        </button>
      </div>
      <CropperModal
        isOpen={cropperModalOpen}
        onRequestClose={() => setCropperModalOpen(false)}
        imageSrc={
          currentImageFile ? URL.createObjectURL(currentImageFile) : null
        }
        onCrop={handleCrop} // Pasar la función handleCrop como callback
        isLoading={isLoading}
      />
    </div>
  );
};

// Placeholder function to get user's profile logo URL
const getUserProfileLogoUrl = async (userId) => {
  return "";
};

export default GameConfig;
