import { db, storage, auth } from "../firebaseConfig";
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import { ref, deleteObject, listAll } from "firebase/storage";

// Función para eliminar todas las subcolecciones y documentos en Firestore
const deleteCollection = async (collectionRef) => {
  const snapshot = await getDocs(collectionRef);
  const deletePromises = snapshot.docs.map((doc) => deleteDoc(doc.ref));
  await Promise.all(deletePromises);
};

// Función para eliminar todas las carpetas y archivos en el almacenamiento
const deleteFolder = async (folderRef) => {
  const listResult = await listAll(folderRef);

  const deletePromises = listResult.items.map((itemRef) =>
    deleteObject(itemRef)
  );
  await Promise.all(deletePromises);

  const subfolderDeletePromises = listResult.prefixes.map((subfolderRef) =>
    deleteFolder(subfolderRef)
  );
  await Promise.all(subfolderDeletePromises);
};

// Función para eliminar un evento y su carpeta correspondiente
const deleteEvent = async (eventId) => {
  const user = auth.currentUser;
  if (!user) {
    console.error("No user is authenticated");
    return;
  }

  const eventDocRef = doc(db, `users/${user.uid}/events/${eventId}`);
  const gamesCollectionRef = collection(
    db,
    `users/${user.uid}/events/${eventId}/games`
  );

  // Eliminar todos los documentos de la subcolección 'games'
  await deleteCollection(gamesCollectionRef);

  // Eliminar el documento del evento
  await deleteDoc(eventDocRef);

  // Eliminar los archivos del almacenamiento
  const storageRef = ref(storage, `users/${user.uid}/events/${eventId}`);
  await deleteFolder(storageRef);

  console.log("Event and folder deleted successfully");
};

export default deleteEvent;
