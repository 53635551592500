// src/components/ImageCropper.js

import React from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./ImageCropper.css";

const ImageCropper = ({ image, aspectRatio = 1, cropperRef }) => {
  return (
    <div className="cropper-container">
      <Cropper
        src={image}
        style={{ height: 400, width: "100%" }}
        aspectRatio={aspectRatio}
        guides={false}
        ref={cropperRef}
        viewMode={1}
        background={false}
        autoCropArea={1}
        checkOrientation={false}
      />
    </div>
  );
};

export default ImageCropper;
