// src/components/modals/ResetPasswordModal.js

import React, { useState, useEffect } from "react";
import LoadingSpinner from "../LoadingSpinner";
import "./ResetPasswordModal.css";

const ResetPasswordModal = ({ onSubmit, onCancel, isLoading, actionName }) => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [failedAttempts, setFailedAttempts] = useState(0);
  const [lockedOut, setLockedOut] = useState(false);

  useEffect(() => {
    setError("");
    setPassword("");
    setFailedAttempts(0);
    setLockedOut(false);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (lockedOut) {
      setError("Too many failed attempts. Please try again later.");
      return;
    }
    setError("");
    try {
      await onSubmit(password);
    } catch (err) {
      if (err.code === "auth/too-many-requests") {
        setError("Too many failed attempts. Please try again later.");
        setLockedOut(true);
      } else if (err.code === "auth/wrong-password") {
        setError("Incorrect password. Please try again.");
        setFailedAttempts((prev) => prev + 1);
        if (failedAttempts + 1 >= 3) {
          setLockedOut(true);
          setError("Too many failed attempts. Please try again later.");
        }
      } else {
        setError("An unexpected error occurred. Please try again.");
      }
      // Aquí no usamos alert(), solo actualizamos el estado 'error'
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Enter Password to {actionName}</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            className="password-input"
            disabled={isLoading || lockedOut}
          />
          {error && <p className="error-message">{error}</p>}
          <div className="modal-actions">
            <button
              type="submit"
              className="confirm-button"
              disabled={isLoading || lockedOut}
            >
              {isLoading ? "Verifying..." : "Confirm"}
            </button>
            <button
              type="button"
              className="cancel-button"
              onClick={onCancel}
              disabled={isLoading}
            >
              Cancel
            </button>
          </div>
        </form>
        {isLoading && <LoadingSpinner message="Verifying password..." />}
      </div>
    </div>
  );
};

export default ResetPasswordModal;
