// src/components/games/MemoryMatchGame/MemoryMatchGame.js

import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db, auth } from "../../../firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
} from "firebase/auth";
import {
  collection,
  addDoc,
  getDocs,
  getDoc,
  query,
  orderBy,
  deleteDoc,
  doc,
  limit,
} from "firebase/firestore";
import ResetPasswordModal from "../../modals/ResetPasswordModal";
import GameNavbar from "../../common/GameNavbar";
import Sidebar from "../../common/Sidebar";
import Podium from "../../common/Podium";
import LoadingSpinner from "../../LoadingSpinner";
import styles from "./MemoryMatchGame.module.css";
import { motion } from "framer-motion";

const MemoryMatchGame = () => {
  const { eventId, gameId } = useParams();
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const [playerName, setPlayerName] = useState("");
  const [currentPlayer, setCurrentPlayer] = useState("");
  const [cards, setCards] = useState([]);
  const [flippedCards, setFlippedCards] = useState([]);
  const [matchedCards, setMatchedCards] = useState([]);
  const [timer, setTimer] = useState(0);
  const [images, setImages] = useState([]);
  const [backImage, setBackImage] = useState("");
  const [loading, setLoading] = useState(true);
  const [leaderboard, setLeaderboard] = useState([]);
  const [gameFinished, setGameFinished] = useState(false);
  const timerIntervalRef = useRef(null);
  const [showResetModal, setShowResetModal] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [showPodium, setShowPodium] = useState(false);
  const [topPlayers, setTopPlayers] = useState([]);
  const [isVerifyingPassword, setIsVerifyingPassword] = useState(false);
  const [customization, setCustomization] = useState({});
  const [navbarLogo, setNavbarLogo] = useState(null);

  // Function to format time from seconds to MM:SS
  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  // Fetch game data when component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!user) return;

        // Get game customization data
        const gameDocRef = doc(
          db,
          `users/${user.uid}/events/${eventId}/games/${gameId}`
        );
        const gameDoc = await getDoc(gameDocRef);
        if (gameDoc.exists()) {
          const gameData = gameDoc.data();
          setCustomization(gameData.customization || {});

          // Get navbar logo if available
          if (gameData.customization.navbarLogoUrl) {
            setNavbarLogo(gameData.customization.navbarLogoUrl);
          } else {
            setNavbarLogo(null);
          }

          // Use customization images
          const imageUrls = gameData.customization.images || [];
          setImages(imageUrls);

          // Get and set back image for cards
          const backImgUrl = gameData.customization.backImageUrl || "";
          setBackImage(backImgUrl);

          // Get leaderboard from Firestore
          const scoresRef = collection(
            db,
            `users/${user.uid}/events/${eventId}/games/${gameId}/scores`
          );
          const scoresSnapshot = await getDocs(
            query(scoresRef, orderBy("time", "asc"))
          );
          const scores = scoresSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setLeaderboard(scores);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching game data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [eventId, gameId, user, navigate]);

  // Initialize the cards when images are loaded
  useEffect(() => {
    if (images.length > 0) {
      prepareCards();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  // Function to prepare the cards
  const prepareCards = () => {
    const duplicatedImages = [...images, ...images]; // Create pairs
    const shuffledImages = duplicatedImages.sort(() => Math.random() - 0.5);

    const gameCards = shuffledImages.map((imageUrl, index) => ({
      id: index,
      image: imageUrl,
      matched: false,
    }));
    setCards(gameCards);
    setFlippedCards([]); // All cards are face down
    setMatchedCards([]); // No cards are matched
  };

  // Initialize the game
  const initializeGame = () => {
    // Start the timer and reset the game state
    if (timerIntervalRef.current) {
      clearInterval(timerIntervalRef.current);
      timerIntervalRef.current = null;
    }

    // Prepare new shuffled cards
    prepareCards();

    setFlippedCards([]);
    setMatchedCards([]);
    setTimer(0);
    setGameFinished(false);

    // Start the timer
    timerIntervalRef.current = setInterval(() => {
      setTimer((prevTime) => prevTime + 1);
    }, 1000);
  };

  // Handle card click
  const handleCardClick = async (index) => {
    if (!currentPlayer || gameFinished) return; // Prevent clicking when game hasn't started or has finished
    if (flippedCards.length === 2) return;
    if (flippedCards.includes(index)) return;
    if (cards[index].matched) return;

    const newFlippedCards = [...flippedCards, index];
    setFlippedCards(newFlippedCards);

    if (newFlippedCards.length === 2) {
      const [firstIndex, secondIndex] = newFlippedCards;
      if (cards[firstIndex].image === cards[secondIndex].image) {
        // Pair found
        const newMatchedCards = [...matchedCards, firstIndex, secondIndex];
        setMatchedCards(newMatchedCards);
        setCards((prevCards) =>
          prevCards.map((card, idx) =>
            idx === firstIndex || idx === secondIndex
              ? { ...card, matched: true }
              : card
          )
        );
        setFlippedCards([]);
        if (newMatchedCards.length === cards.length) {
          // Game finished
          await handleSaveScore(); // Save score at the end
          handleGameFinish();
        }
      } else {
        // No match
        setTimeout(() => {
          setFlippedCards([]);
        }, 1000);
      }
    }
  };

  // Handle game finish
  const handleGameFinish = () => {
    clearInterval(timerIntervalRef.current);
    timerIntervalRef.current = null;
    setGameFinished(true);
    // Flip all cards back to face down
    setMatchedCards([]);
    setFlippedCards([]);
  };

  // Handle start button
  const handleStart = async () => {
    if (!playerName.trim()) {
      alert("Please enter your name to start the game.");
      return;
    }

    if (currentPlayer && !gameFinished) {
      // If there is already a current player and the game hasn't finished, save their score before starting a new game
      await handleSaveScore();
    }

    setCurrentPlayer(playerName);
    initializeGame();
  };

  // Handle saving score
  const handleSaveScore = async () => {
    if (!user) return;
    if (!currentPlayer) return;

    try {
      const timestamp = new Date();

      await addDoc(
        collection(
          db,
          `users/${user.uid}/events/${eventId}/games/${gameId}/scores`
        ),
        {
          name: currentPlayer,
          time: timer, // Use 'time' for consistency
          timestamp: timestamp,
        }
      );

      // Update leaderboard locally without duplication
      setLeaderboard((prev) => [
        ...prev,
        { name: currentPlayer, time: timer, timestamp },
      ]);
    } catch (error) {
      console.error("Error saving score:", error);
      alert("There was an error saving your score. Please try again.");
    }
  };

  // Function to get top players and show the Podium
  const fetchTopPlayers = async () => {
    if (!user) return;
    try {
      const scoresRef = collection(
        db,
        `users/${user.uid}/events/${eventId}/games/${gameId}/scores`
      );
      const topScoresSnapshot = await getDocs(
        query(scoresRef, orderBy("time", "asc"), limit(3))
      );
      const topScores = topScoresSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTopPlayers(topScores);
      setShowPodium(true);
    } catch (error) {
      console.error("Error fetching top players:", error);
      alert("There was an error fetching the top players.");
    }
  };

  // Handle menu actions
  const handleMenuClick = (action) => {
    if (action === "resetLeaderboard") {
      setActionType("resetLeaderboard");
      setShowResetModal(true);
    } else if (action === "finishGame") {
      setActionType("finishGame");
      setShowResetModal(true);
    } else if (action === "redirectToEvent") {
      setActionType("redirectToEvent");
      setShowResetModal(true);
    }
  };

  // Handle logo click
  const handleLogoClick = () => {
    setActionType("redirectToEvent");
    setShowResetModal(true);
  };

  // Handle resetting the leaderboard
  const handleResetLeaderboard = async () => {
    setLoading(true);
    try {
      // Delete all scores
      const scoresRef = collection(
        db,
        `users/${user.uid}/events/${eventId}/games/${gameId}/scores`
      );
      const scoresSnapshot = await getDocs(scoresRef);
      const deletePromises = scoresSnapshot.docs.map((doc) =>
        deleteDoc(doc.ref)
      );
      await Promise.all(deletePromises);
      setLeaderboard([]);

      // Reset game states to show the start screen
      setGameFinished(false);
      setCurrentPlayer("");
      setPlayerName("");
      setFlippedCards([]);
      setMatchedCards([]);
      setTimer(0);
    } catch (error) {
      console.error("Error resetting leaderboard:", error);
      alert("There was an error resetting the leaderboard. Please try again.");
    } finally {
      setLoading(false);
      setShowResetModal(false);
    }
  };

  // Handle finishing the game from the navbar (show Podium)
  const handleFinishGame = async () => {
    setLoading(true);
    try {
      // Get top players and show the Podium
      await fetchTopPlayers();

      // Reset leaderboard
      await handleResetLeaderboard();
    } catch (error) {
      console.error("Error finishing game:", error);
    } finally {
      setLoading(false);
      setShowResetModal(false);
    }
  };

  // Handle complete game restart from the Podium
  const handleRestartGame = () => {
    // Only reset the game, not the leaderboard
    setShowPodium(false);
    setGameFinished(false);
    setCurrentPlayer("");
    setTimer(0);
    setMatchedCards([]);
    setFlippedCards([]);
    setPlayerName("");
  };

  // Handle password verification
  const handleVerifyPassword = async (password) => {
    setIsVerifyingPassword(true);
    try {
      const credential = EmailAuthProvider.credential(user.email, password);
      await reauthenticateWithCredential(user, credential);
      if (actionType === "resetLeaderboard") {
        await handleResetLeaderboard();
      } else if (actionType === "finishGame") {
        await handleFinishGame();
      } else if (actionType === "redirectToEvent") {
        navigate(`/event/${eventId}`);
      }
      setShowResetModal(false);
    } catch (error) {
      console.error("Error verifying password:", error);
      throw error;
    } finally {
      setIsVerifyingPassword(false);
    }
  };

  // Apply customization styles
  const gameStyles = {
    "--sidebar-bg": customization.sidebarColor || "#9036d5",
    "--sidebar-text-color": customization.sidebarTextColor || "#ffffff",
    "--navbar-bg": customization.navbarColor || "#5d3dfd",
    "--navbar-title-text-color":
      customization.navbarTitleTextColor || "#ffffff",
    "--gameboard-background-color":
      customization.gameboardBackgroundColor || "#ffffff",
    "--card-border-color": customization.cardBorderColor || "#000000",
  };

  if (loading) {
    return <LoadingSpinner message={"Processing..."} />;
  }

  return (
    <div className={styles.memoryMatchGame} style={gameStyles}>
      <GameNavbar
        customization={customization}
        onMenuClick={handleMenuClick}
        navbarLogo={navbarLogo}
        onLogoClick={handleLogoClick}
      />
      <div className={styles.gameLayout}>
        <Sidebar
          currentPlayer={currentPlayer}
          timer={timer}
          leaderboard={leaderboard}
          formatTime={formatTime}
          customization={{
            sidebarColor: customization.sidebarColor || "#9036d5",
            sidebarTextColor: customization.sidebarTextColor || "#ffffff",
          }}
        />
        <div
          className={styles.gameBoardContainer}
          style={{
            backgroundColor:
              customization.gameboardBackgroundColor || "#ffffff",
          }}
        >
          {/* Game board */}
          <div className={styles.gameBoard}>
            {cards.map((card, index) => {
              const isFlipped =
                flippedCards.includes(index) || matchedCards.includes(index);
              return (
                <motion.div
                  key={index}
                  className={`${styles.card}`}
                  onClick={() => handleCardClick(index)}
                  whileHover={{ scale: 1.05 }}
                >
                  <motion.div
                    className={`${styles.cardInner} ${
                      isFlipped ? styles.flipped : ""
                    }`}
                    animate={{
                      rotateY: isFlipped ? 180 : 0,
                    }}
                    transition={{ duration: 0.6, ease: "easeInOut" }}
                  >
                    <div
                      className={styles.cardFront}
                      style={{ backgroundImage: `url(${card.image})` }}
                    ></div>
                    <div
                      className={styles.cardBack}
                      style={{
                        backgroundImage: backImage
                          ? `url(${backImage})`
                          : "none",
                        backgroundColor: backImage ? "transparent" : "#9036d5",
                      }}
                    ></div>
                  </motion.div>
                </motion.div>
              );
            })}
          </div>

          {/* Start screen to enter player's name */}
          {!currentPlayer && !gameFinished && (
            <div className={styles.startContainer}>
              <input
                type="text"
                className={styles.playerInput}
                placeholder="Enter your name"
                value={playerName}
                onChange={(e) => setPlayerName(e.target.value)}
              />
              <button className={styles.startButton} onClick={handleStart}>
                Start Game
              </button>
            </div>
          )}

          {/* Game finished screen */}
          {gameFinished && (
            <div className={styles.gameFinished}>
              <h2>Congratulations!</h2>
              <p>Your Time: {formatTime(timer)}</p>
              <input
                type="text"
                value={playerName}
                onChange={(e) => setPlayerName(e.target.value)}
                placeholder="Enter the next player's name"
                className={styles.playerInput}
                disabled={loading}
              />
              <button
                onClick={handleStart}
                className={styles.startButton}
                disabled={loading}
              >
                Start New Game
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Show Podium if active */}
      {showPodium && (
        <Podium
          topPlayers={topPlayers}
          onRestart={handleRestartGame}
          formatTime={formatTime}
        />
      )}

      {/* Modals */}
      {showResetModal && (
        <ResetPasswordModal
          onSubmit={handleVerifyPassword}
          onCancel={() => setShowResetModal(false)}
          isLoading={isVerifyingPassword}
          actionName={
            actionType === "resetLeaderboard"
              ? "Reset Leaderboard"
              : actionType === "finishGame"
              ? "Finalize Game"
              : actionType === "redirectToEvent"
              ? "Return to Event"
              : ""
          }
        />
      )}
    </div>
  );
};

export default MemoryMatchGame;
