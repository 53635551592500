import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "./ConfirmModal";
import "./EventCard.css";

const EventCard = ({ event, onDelete }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleConfigure = () => {
    navigate(`/event/${event.id}`);
  };

  const handleDelete = () => {
    setModalOpen(true);
  };

  const confirmDelete = () => {
    onDelete(event.id);
    setModalOpen(false);
  };

  const cancelDelete = () => {
    setModalOpen(false);
  };

  return (
    <>
      <div className="event-card">
        <h4>{event.name}</h4>
        {/* Eliminado el código relacionado con `createdAt` */}
        <button className="configure-button" onClick={handleConfigure}>
          Configure
        </button>
        <button className="delete-button" onClick={handleDelete}>
          Delete
        </button>
      </div>
      {isModalOpen && (
        <ConfirmModal
          message="Are you sure you want to delete this event?"
          onConfirm={confirmDelete}
          onCancel={cancelDelete}
        />
      )}
    </>
  );
};

export default EventCard;
